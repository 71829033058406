<template>
	<modal-wrapper @close="$emit('close')">
		<div class="modal-body-data">
			<section class="patient-data" v-if="record">
				<h2>Patientendaten</h2>
				<div class="wrapper">
					<div class="data-group">
						<p>Vorname:</p>
						<p>{{ record.extended.patient.vorname }}</p>
					</div>
					<div class="data-group">
						<p>Geschlecht:</p>
						<p>
							{{
								record.extended.patient.geschlecht === 'm'
									? 'Männlich'
									: 'Weiblich'
							}}
						</p>
					</div>
					<div class="data-group">
						<p>Nachname / Patenten ID:</p>
						<p>{{ record.extended.patient.nachname }}</p>
					</div>
					<div class="data-group">
						<p>Versicherung:</p>
						<p>{{ record.extended.patient.versicherung }}</p>
					</div>
					<div class="data-group">
						<p>Alter:</p>
						<p>{{ record.extended.patient.alter }}</p>
					</div>
					<div class="data-group">
						<p>XML-Nummer:</p>
						<p>{{ record.extended.order.xmlNumber }}</p>
					</div>
					<div class="data-group">
						<p>Versorgung:</p>
						<p>{{ record.extended.patient.versorgung }}</p>
					</div>
				</div>
			</section>
			<section class="additional-information">
				<div class="info-wrapper">
					<h2>Daten</h2>
					<div class="wrapper">
						<div class="data-group">
							<p>Material:</p>
							<p>{{ record.extended.order.material }}</p>
						</div>
						<div class="data-group">
							<p>Zahnfarbe:</p>
							<p>{{ record.extended.order.color }}</p>
						</div>
					</div>
				</div>

				<div class="info-wrapper">
					<h2>Termine</h2>
					<div class="wrapper">
						<div class="data-group first">
							<p>{{ record.extended.order.firstDateType }}:</p>
							<p>{{ record.extended.order.firstDate | momentDateTime }}</p>
						</div>
						<div
							class="data-group second"
							v-if="record.extended.order.secondDate !== ''"
						>
							<p>{{ record.extended.order.secondDateType }}:</p>
							<p>{{ record.extended.order.secondDate | momentDateTime }}</p>
						</div>
						<div
							class="data-group third"
							v-if="record.extended.order.thirdDate !== ''"
						>
							<p>{{ record.extended.order.thirdDateType }}:</p>
							<p>{{ record.extended.order.thirdDate | momentDateTime }}</p>
						</div>
					</div>
				</div>
			</section>
			<section class="order">
				<div class="wrapper">
					<h2>Auftrag:</h2>
					<p>
						{{ record.extended.order.order }}
					</p>
				</div>
			</section>
			<section class="schema-wrapper">
				<h2>Schema</h2>
				<div class="schema">
					<div
						class="schema-values top-row"
						:class="{
							last: firstRow.length - 1 === parseInt(index)
						}"
						v-for="(s, index) in firstRow"
						:key="s.order"
					>
						<p>{{ s.value || '-' }}</p>
						<p>{{ s.name }}</p>
					</div>
					<div
						class="schema-values bottom-row"
						:class="{
							last: parseInt(index) === lastRow.length - 1
						}"
						v-for="(s, index) in lastRow"
						:key="s.order"
					>
						<p>{{ s.name }}</p>
						<p>{{ s.value || '-' }}</p>
					</div>
				</div>
			</section>
			<section class="controls">
				<div class="left" v-if="$store.getters.getAppMode === 'lab'">
					<button
						class="button btn-ok"
						@click="feedBackOK(record.UUID)"
						v-if="[0, 2].includes(record.status)"
					>
						Akzeptieren
					</button>
					<button
						class="button btn-nok"
						@click="feedBackNOK(record.UUID)"
						v-if="record.status === 0"
					>
						Nicht akzeptieren
					</button>
				</div>
				<div class="right" :class="{ doctor: 'lab' in record }">
					<button
						class="button"
						@click="saveAsPDF"
						v-if="$store.getters.getAppMode === 'lab'"
					>
						Als PDF speichern
					</button>
					<button class="button" @click="$emit('close')">Schließen</button>
				</div>
			</section>
		</div>
		<patient-data-PDF
			:record="record"
			v-if="$store.getters.getAppMode === 'lab'"
		></patient-data-PDF>
	</modal-wrapper>
</template>

<script>
import formatMixin from '@/mixins/format.mixin';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';
export default {
	name: 'patientDataModal',
	props: ['record'],
	components: {
		modalWrapper: () => import('../modal-wrapper.vue'),
		patientDataPDF: () => import('../pdf/patient-data.vue')
	},
	mixins: [formatMixin],
	computed: {
		firstRow: function () {
			let d = [...this.record.extended.schema];
			return d.sort(this.compare).splice(0, 16);
		},
		lastRow: function () {
			let d = [...this.record.extended.schema];
			return d.sort(this.compare).splice(16, 32);
		}
	},
	methods: {
		compare(a, b) {
			if (a.order < b.order) {
				return -1;
			}
			if (a.order > b.order) {
				return 1;
			}
			return 0;
		},
		saveAsPDF() {
			let that = this;
			let doc = new jsPDF({ unit: 'mm' });
			document.getElementById('pdf').style.visibility = 'visible';
			doc.html(document.getElementById('pdf'), {
				callback: function (doc) {
					const pageCount = doc.internal.getNumberOfPages();
					for (var i = 1; i <= pageCount; i++) {
						doc.text(String(i), 196, 285);
					}
					doc.save('Akte_' + that.record.id + '_LabLoad');
					document.getElementById('pdf').style.visibility = 'hidden';
				},
				margin: [5, 20, 20, 20],
				x: 5,
				y: 5
			});
		},
		feedBackOK(recordID) {
			this.sendFeedBack(recordID, true, '', '');
		},
		async feedBackNOK(recordID) {
			const titles = ['Nachricht an den Zahlarzt', 'Patientenreferenz'];
			const htmls = [
				'<h2>Bitte fehlende Informationen ergänzen</h2><br><p>Senden Sie dem Zahnarzt eine Nachricht:</p>',
				'<p>Geben Sie eine Patientenreferenz für die Praxis an. Vermeiden Sie es bitte, über dieses Feld Patientendaten zu verschicken</p>'
			];
			const inputs = ['textarea', 'text'];
			const queue = await Swal.mixin({
				input: 'textarea',
				confirmButtonText: 'Weiter &rarr;',
				showCancelButton: true,
				cancelButtonText: 'Abbrechen',
				progressSteps: ['1', '2']
			});
			const values = [];
			let currentStep;

			for (currentStep = 0; currentStep < 2; ) {
				const result = await queue.fire({
					title: `${titles[currentStep]}`,
					inputValue: '',
					html: htmls[currentStep],
					input: inputs[currentStep],
					showCancelButton: currentStep > 0,
					currentProgressStep: currentStep
				});

				if (result.value) {
					values[currentStep] = result.value;
					currentStep++;
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					currentStep--;
				} else {
					break;
				}
			}

			if (values) {
				await this.sendFeedBack(recordID, false, values[0], values[1]);
			}
		},
		async sendFeedBack(recordID, isOK, text, name) {
			try {
				await this.$api.post(
					'/lab/notify',
					{
						recordID: recordID,
						isOK: isOK,
						text: text,
						patient: name
					},
					{
						headers: {
							Authorization: `Bearer ${this.$store.getters.getUserToken}`
						}
					}
				);
				Swal.fire({
					icon: 'success',
					title: 'Benachrichtigung erfolgreich gesendet.',
					text: 'Die Benachrichtigung an den Zahnarzt wurde erfolgerich gesendet.'
				});
			} catch {
				Swal.fire({
					icon: 'error',
					title: 'Benachrichtigung konnte nicht gesendet werden.',
					text: 'Die Benachrichtigung an den Zahnarzt konnte nicht erfolgerich gesendet werden.'
				});
			}
		}
	}
};
</script>

<style lang="scss">
.modal-body-data {
	display: grid;
	grid-template-columns: 9fr 11fr;
	grid-template-rows: 1fr 0.5fr 1fr 1fr 0.5fr;
	height: 100%;
	max-height: 100%;
	padding: 1rem 0 0 0;

	.patient-data {
		grid-row: 1/3;
		grid-column: 1;
		display: flex;
		flex-direction: column;
		padding: 1rem;
		.wrapper {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 12px;
		}
	}

	.additional-information {
		grid-row: 1/3;
		grid-column: 2;
		display: grid;
		grid-template-columns: 1fr 1fr;
		.info-wrapper {
			padding: 1rem;
		}
	}

	.order {
		grid-row: 3;
		grid-column: 1/3;
		margin: 0.75rem 0;
		.wrapper {
			height: 100%;
			padding: 1rem;
			margin-top: 0;
			p {
				@extend textarea;
				margin-top: 0.5rem;
				font-weight: bold;
				font-size: 1rem;
				line-height: 1rem;
			}
		}
	}

	.controls {
		grid-column: 1/3;
		grid-row: 5;
		padding: 1rem;
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
	}
	.wrapper {
		margin-top: 0.5rem;
		.data-group {
			display: grid;
			grid-template-columns: 1fr 1fr;
			margin: 0.25rem 0;
			justify-content: center;
			align-items: center;
			p {
				&:last-of-type {
					@extend input;
					height: auto;
					word-break: break-all;
				}
			}
		}
	}

	.schema-wrapper {
		grid-row: 4;
		grid-column: 1/3;
		padding: 1rem;
		.schema {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			justify-content: center;
			align-content: center;
			margin-top: 1rem;
			.schema-values {
				width: calc(100% / 16);
				display: flex;
				flex-direction: column;
				p:first-child {
					max-width: 100%;
					background-color: transparent;
					border: none;
					font-size: 1.05rem;
					padding: 0 5px;
					text-align: center;
				}
				p:last-child {
					text-align: center;
					background-color: transparent;
				}
				&.top-row {
					border-width: 0 1px 1px 0;
					border-style: solid;
					border-color: $text-color;
					p:last-child {
						border-top: 1px solid $text-color;
						font-weight: bold;
					}
					&.last {
						border-width: 0 0 1px 0;
					}
				}
				&.bottom-row {
					border-width: 0 1px 0 0;
					border-style: solid;
					border-color: $text-color;
					p:first-child {
						border-bottom: 1px solid $text-color;
						font-weight: bold;
					}
					&.last {
						border-width: 0 0 0 0;
					}
				}
			}
		}
	}
	.controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		.left,
		.right {
			flex: 0 0 45%;
			display: flex;
			justify-content: space-evenly;
		}
		.doctor {
			flex: 0 0 97.5%;
			display: flex;
			justify-content: flex-end;
		}
	}
	@media screen and (max-width: 1600px) {
		.additional-information {
			grid-row: 1/3;
			grid-column: 2;
			display: grid;
			grid-template-rows: 1fr 1fr;
			grid-template-columns: 1fr;
		}
	}
	@media screen and (max-width: 1100px) {
		.patient-data {
			grid-row: 1;
			grid-column: 1/3;
		}
		.additional-information {
			grid-row: 2;
			grid-column: 1/3;
			display: grid;
			.info-wrapper {
				padding: 0.25rem 1rem;
			}
		}
		.order {
			.wrapper {
				padding: 0.25rem 1rem;
			}
		}
		.schema-wrapper {
			display: none;
		}
	}
}
</style>
